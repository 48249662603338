import { createApp } from 'vue'
import App from './components/AgeVerifier.vue'
import HelpWindow from './components/HelpWindow.vue'
import NewsletterSignup from './components/NewsletterSignup.vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

const app = createApp(App, {})
    .mount('#ageVerifier');

const helpWindow = createApp(HelpWindow, {});

helpWindow.use(VueReCaptcha, { siteKey: '6LdRR4opAAAAALhAsUXFvQ4sEPaIezm0jDZNfb3y' })

helpWindow.mount('#helpWindow');

const newsletterSignup = createApp(NewsletterSignup, {});

newsletterSignup.use(VueReCaptcha, { siteKey: '6LdRR4opAAAAALhAsUXFvQ4sEPaIezm0jDZNfb3y' })

newsletterSignup.mount('#newsletterSignup');