import API from '../services/axios.js';
import Routing from 'fos-router';

export default {
    subscribe_newsletter: (data) => {
        return new Promise((resolve, reject) => {
            API.post('/api/newsletter-subscribe', data).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    send_message: (data) => {
        return new Promise((resolve, reject) => {
            API.post('/api/send-message', data).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        });
    },

    search_stores: (strains, stores) => {
        return new Promise((resolve, reject) => {
            API.post('/api/search-stores', {
                stores: stores,
                strains: strains,
            }).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    geocode_address: (address) => {
        return new Promise((resolve, reject) => {
            API.post('/api/geocode-address', {
                address: address
            }).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        });
    },
};