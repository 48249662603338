<template>
  <div>
    <div v-if="showOverlay" class="overlay">
      <div class="help-modal text-center">
        <XCircleIcon class="close-btn" @click="closeModal" />
      <!-- input form -->
        <div class="relative bg-white">
        <div class="lg:absolute lg:inset-0 lg:left-1/2">
            <img class="h-64 w-full bg-gray-50 object-cover sm:h-80 lg:absolute lg:h-full" src="/images/waka-flame.jpg" alt="">
        </div>
        <div class="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2">
            <div class="px-6 lg:px-8">
            <div class="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                <h2 class="text-3xl font-bold tracking-tight text-gray-900">Hit us up</h2>
                <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                    <div>
                    <label for="first-name" class="text-left block text-sm font-semibold leading-6 text-gray-900">First name</label>
                    <div class="mt-2.5">
                        <input type="text" v-model="message.first_name" name="first-name" id="first-name" autocomplete="given-name" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                    </div>
                    </div>
                    <div>
                    <label for="last-name" class="text-left block text-sm font-semibold leading-6 text-gray-900">Last name</label>
                    <div class="mt-2.5">
                        <input type="text" v-model="message.last_name" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                    </div>
                    </div>
                    <div>
                    <label for="email" class="text-left block text-sm font-semibold leading-6 text-gray-900">Email</label>
                    <div class="mt-2.5">
                        <input id="email" v-model="message.email" name="email" type="email" autocomplete="email" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                    </div>
                    </div>
                    <div>
                    <div class="flex justify-between text-sm leading-6">
                        <label for="phone" class="text-left block font-semibold text-gray-900">Phone</label>
                        <p id="phone-description" class="text-gray-400">Optional</p>
                    </div>
                    <div class="mt-2.5">
                        <input type="tel" v-model="message.phone" name="phone" id="phone" autocomplete="tel" aria-describedby="phone-description" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                    </div>
                    </div>
                    <div class="sm:col-span-2">
                    <label for="company" class="text-left block text-sm font-semibold leading-6 text-gray-900">Company</label>
                    <div class="mt-2.5">
                        <input type="text" v-model="message.company" name="company" id="company" autocomplete="organization" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                    </div>
                    </div>
                    <div class="sm:col-span-2">
                    <div class="flex justify-between text-sm leading-6">
                        <label for="message" class="block text-sm font-semibold leading-6 text-gray-900">How can we help you?</label>
                        <p id="message-description" class="text-gray-400">Max 500 characters</p>
                    </div>
                    <div class="mt-2.5">
                        <textarea v-model="message.message" id="message" name="message" rows="4" aria-describedby="message-description" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"></textarea>
                    </div>
                    </div>
                </div>
                <div class="mt-10 flex justify-end border-t border-gray-900/10 pt-8">
                    <button @click.prevent="sendMessage" class="body-link-button">
                      <span v-if="!isSending">Send</span>
                      <span v-else>Sending...</span>
                    </button>
                </div>
            </div>
            </div>
        </div>
        </div>


<!-- end input form -->
      </div>
    </div>

    <button @click="openModal" class="body-help-button">
      Questions?
    </button>

<!-- begin confirmation -->
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <div v-if="showConfirmation" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <CheckCircleIcon class="h-6 w-6 text-green-400" aria-hidden="true" />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">Message received</p>
                <p class="mt-1 text-sm text-gray-500">We'll reach out as soon as we can, thanks!</p>
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button type="button" @click="showConfirmation = false" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
<!-- end confirmation -->
  </div>
</template>

<script>
import XCircleIcon from '@heroicons/vue/20/solid/XCircleIcon.js';
import actions from '../store/actions.js';
import { useReCaptcha } from 'vue-recaptcha-v3';
import { CheckCircleIcon } from '@heroicons/vue/24/outline'
import { XMarkIcon } from '@heroicons/vue/20/solid'

export default {
  components: {
    XCircleIcon,
    CheckCircleIcon,
    XMarkIcon,
  },
  data() {
    return {
      showConfirmation: false,
      showOverlay: false,
      isSending: false,
      message: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        company: '',
        message: '',
        recaptcha: '',
      }
    };
  },
  mounted() {

  },
  methods: {
    openModal() {
      this.showOverlay = true;
    },
    closeModal() {
      this.showOverlay = false;
    },
    async sendMessage() {
      this.isSending = true;
      await this.$recaptchaLoaded();

      const token = await this.$recaptcha('contact_form')

      this.message.recaptcha = token;

      actions.send_message(this.message)
        .then(() => {
          this.showConfirmation = true;
          this.showOverlay = false;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isSending = false;
        });

      console.log('Token: ', token);
    },
  }
};
</script>
