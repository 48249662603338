<template>
  <div>
    <div v-if="showOverlay" class="overlay">
      <div class="modal text-center">
        <span class="close-btn" @click="closeModal">&times;</span>
        <h3>Age Verification</h3>
        <p class="mb-4">Are you above the age of 21?</p>
        <a class="body-link-button-age-verification" @click="verifyAge">Yes</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showOverlay: false,
      ageVerificationKey: 'ageVerified'
    };
  },
  mounted() {
    this.checkAgeVerification();
  },
  methods: {
    openModal() {
      this.showOverlay = true;
    },
    closeModal() {
      this.showOverlay = false;
    },
    verifyAge() {
      localStorage.setItem(this.ageVerificationKey, 'true');
      this.closeModal();
    },
    checkAgeVerification() {
      const ageVerified = localStorage.getItem(this.ageVerificationKey);
      if (!ageVerified) {
        this.openModal();
      }
    }
  }
};
</script>
