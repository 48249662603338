<template>
  <div class="grid grid-cols-12 border-t border-slate-200 sm:pt-3 lg:pt-4 ">
    <div class="col-span-6">
      <div class="masthead-logo-black"></div>
      </div>
      <div class="col-span-6">
      <div class="lg:flex lg:items-center lg:justify-between">
        <div>
          <h3 class="text-sm font-semibold leading-6">Subscribe to our newsletter</h3>
          <p class="mt-2 text-sm leading-6">The latest news, articles, and resources, sent to your inbox weekly.</p>
        </div>
        <div class="mt-6 sm:flex sm:max-w-md lg:mt-0">
          <label for="email-address" class="sr-only">Email address</label>
          <input type="email" name="email-address" id="email-address" v-model="message.email" autocomplete="email" required class="w-full min-w-0 appearance-none rounded-md border-0 bg-white px-3 py-1.5 text-base shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-black focus:ring-2 focus:ring-inset text-black focus:ring-indigo-600 sm:w-56 sm:text-sm sm:leading-6" placeholder="Enter your email">
          <div class="mt-4 sm:ml-4 sm:mt-0 sm:flex-shrink-0">
              <button @click.prevent="subscribeNewsletter" type="submit" class="footer-link-button">Subscribe</button>
          </div>
        </div>
      </div>
    </div>
    <!-- begin confirmation -->
    <!-- Global notification live region, render this permanently at the end of the document -->
    <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6">
      <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
        <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
        <transition enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
          <div v-if="showConfirmation" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div class="p-4">
              <div class="flex items-start">
                <div class="flex-shrink-0">
                  <CheckCircleIcon v-if="success" class="h-6 w-6 text-green-400" aria-hidden="true" />
                  <XMarkIcon v-if="!success" class="h-6 w-6 text-red-400" aria-hidden="true" />
                </div>
                <div class="ml-3 w-0 flex-1 pt-0.5">
                  <p class="mt-1 text-sm text-gray-500">{{ signupMessage }}</p>
                </div>
                <div class="ml-4 flex flex-shrink-0">
                  <button type="button" @click="showConfirmation = false" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <span class="sr-only">Close</span>
                    <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  <!-- end confirmation -->
  </div>
</template>

<script>
import XCircleIcon from '@heroicons/vue/20/solid/XCircleIcon.js';
import actions from '../store/actions.js';
import { useReCaptcha } from 'vue-recaptcha-v3';
import { CheckCircleIcon } from '@heroicons/vue/24/outline'
import { XMarkIcon } from '@heroicons/vue/20/solid'

export default {
  components: {
    XCircleIcon,
    CheckCircleIcon,
    XMarkIcon,
  },
  data() {
    return {
      message: {
        email: '',
        recaptcha: '',
      },
      success:false,
      signupMessage: '',
      showConfirmation: false,
      isSending: false,
    };
  },
  mounted() {

  },
  methods: {
    async subscribeNewsletter() {
      this.isSending = true;
      await this.$recaptchaLoaded();

      const token = await this.$recaptcha('newsletter_signup')

      this.message.recaptcha = token;

      actions.subscribe_newsletter(this.message)
        .then((response) => {
          this.email = '';
          this.success = response.success; 
          this.signupMessage = response.message;
          this.showConfirmation = true;
        })
        .catch((error) => {
          this.signupMessage = "Error subscribing to newsletter";
          console.error(error);
        })
        .finally(() => {
          this.isSending = false;
        });
    },
  }
};
</script>
